<template>
  <div class="pa-5" :style="{ background: bgColor}">
    <div class="progressbar-header d-flex flex-wrap align-center justify-space-between">
      <h5 class="mt-2 mr-3">{{ title }}</h5>
      <div class="d-flex mt-2">
        <div class="d-flex align-center">
          <div class="d-flex flex-column mr-3">
            <span class="progressbar-header__title">PKG</span>
            <span class="progressbar-header__value">{{ pkg }}</span>
          </div>
          <v-img v-if="img" class=" align-self-end mb-2" contain max-height="23" :src="img"/>
        </div>
        <div class="ml-2">
          <div class="d-flex flex-column">
            <span class="progressbar-header__title">Pieces</span>
            <span class="progressbar-header__value">{{ length }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="card-progressbar mt-1">
      <v-progress-linear
          rounded
          height="10"
          background-color="#fff"
          :color="color"
          :value="percent"
      ></v-progress-linear>
    </div>
    <div class="d-flex justify-space-between card-progressbar__text mt-8">
      <p class="mb-0 text-capitalize font-weight-semi-bold">{{ caption }}</p>
      <span class="font-weight-bold">{{ percent.toFixed(2) }}%</span>
    </div>

  </div>
</template>

<script>
export default {
  name: "CardProgressBar",
  props: {
    pkg: {
      type: [String, Number],
      default: 0
    },
    bgColor: {
      type: String,
      default: '#FBFBFB'
    },
    data: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: 'green'
    },
    caption: {
      type: String,
      default: 'green'
    },
    img: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'green'
    },
    current: {
      type: Number,
      default: 0,
    },
    length: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    percent() {
      return  !this.length ? 0 : this.current / (this.length / 100);
    }
  }
}
</script>

<style lang="scss" scoped>
.progressbar-header {
  &__title {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $progress-title;
    text-align: left;
  }
  &__value {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 38px;
    text-transform: uppercase;
    color: $progress-value;
    text-align: center;
  }
}
.card-progressbar {
   margin-bottom: 7px;
   border-radius: 10px;
   padding: 1px;
   border: 1px solid #E0E0E0;
   background-color: #fff;

  .v-progress-linear__determinate,
  .v-progress-linear--rounded {
    border-radius: 10px;
  }

  &__text {
     font-size: 14px;
     line-height: 19px;
   }
}
</style>