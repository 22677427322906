<template>
  <div class="dashboard pb-10">
    <v-row class="dashboard__tabs">
      <v-col color="header" lg="6" class="pa-0 pb-1">
        <v-tabs
          v-model="tab"
          grow
          background-color="header"
          color="tabs_main"
          :dark="isDark"
          centered
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.name"
            :to="tab.to"
            color="tabs_main"
          >
            <div class="caption">
              <span>{{ tab.name }}</span>
            </div>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row v-if="tab !== 3" class="filter-row align-end">
      <v-col cols="12" lg="4" md="4" sm="6">
        <v-row>
          <v-col cols="12" lg="6" md="6">
            <FilterSelect
                label="All Customers"
                query="customer_number"
                :data="customersFilterData"
            ></FilterSelect>
          </v-col>
          <v-col cols="12" lg="6" md="6">
            <FilterSelect
                label="Daily View"
                query="date_offset"
                :data="$dateOffset"
            ></FilterSelect>
          </v-col>
        </v-row>
      </v-col>
<!--      <v-col lg="2" offset-lg="6" md="2" offset-md="6">-->
<!--        <v-select-->
<!--          solo-->
<!--          flat-->
<!--          dense-->
<!--          :items="actionsItems"-->
<!--          label="Actions"-->
<!--          class="dropdown-select action-select"-->
<!--        ></v-select>-->
<!--      </v-col>-->
    </v-row>


    <v-row>
      <v-col cols="12">
        <v-tabs-items  @change="setRoute" v-model="tab" class="dashboard__tabs-items">
          <v-tab-item :id="$router.resolve({name: 'dashboard-operation'}).href">
            <dashboard-tab-operation></dashboard-tab-operation>
          </v-tab-item>

          <v-tab-item :id="$router.resolve({name: 'dashboard-sales'}).href">
            <dashboard-tab-sales></dashboard-tab-sales>
          </v-tab-item>

          <v-tab-item :id="$router.resolve({name: 'dashboard-finance'}).href">
            <dashboard-tab-managerial></dashboard-tab-managerial>
          </v-tab-item>

        </v-tabs-items>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import FilterSelect from '@/components/ui/FilterSelect';
import DashboardTabSales from '@/views/dashboard/tabSales/DashboardTabSales';
import DashboardTabManagerial from '@/views/dashboard/tabSales/DashboardTabFinance';
import DashboardTabOperation from "@/views/dashboard/tabSales/DashboardTabOperation";
import { getCustomers, getListIntervals } from '@/api/dashboard/selects.api';

export default {
  name: "DashboardPage",
  components: {
    DashboardTabOperation,
    FilterSelect,
    DashboardTabSales,
    DashboardTabManagerial,
  },
  data: () => ({
    tab: 0,
    tabs: [
      {to: {name: 'dashboard-operation'}, name: 'Operation'},
      {to: {name: 'dashboard-sales'}, name: 'Sales'},
      {to: {name: 'dashboard-finance'}, name: 'Finance'}
    ],
    time1: null,
    customersFilterData: [],
    dailyViewFilterData: [],
  }),
  created() {
    getCustomers(true).then((data)=>{
      this.customersFilterData = data;
    })
    getListIntervals().then( data => {
      this.dailyViewFilterData = data;
    })
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark
    }
  },
  methods: {
    setRoute(val) {
      this.$router.push(val);
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  &__tabs {
    margin-left: -16px;
    margin-right: -16px;
    background-color: $header;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  }

  &__tabs-items.v-tabs-items {
    background-color: transparent;
  }
}
</style>
