<template>
  <div>
<!--    <v-row v-if="loadingCount < 4">-->
<!--      <v-col cols="12" lg="12"  class="pt-4">-->
<!--        <v-progress-linear rounded  height="3" :value="100/4*loadingCount"  color="loader" background-color="loader_back"></v-progress-linear>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-row>
      <v-col cols="12" lg="4" md="4" sm="6" class="pb-2">
        <card-magic :loading="loading.totalActivity" separate title="Total sales" :value="totalActivity" trend-only-text="Last 7 days" :min-height="300"/>
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="6" class="pb-2">
        <card-magic :loading="loading.directShipment"  separate title="Direct Shipment" :value="directShipment" trend-only-text="Last 7 days"  :min-height="300"/>
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="12" class="pb-2">
        <card-magic :loading="loading.dropShipment"  separate title="Drop Shipment" :value="dropShipment" trend-only-text="Last 7 days"  :min-height="300"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="4" md="4" sm="12" class="pb-2">
        <card-magic :loading="loading.shipmentDetails"  separate title="Factory Shipment Details" :value="shipmentDetails" :min-height="300"/>
      </v-col>

      <v-col cols="12" lg="8" md="8" sm="12" class="pb-2">
        <card-magic :loading="loading.locations" stretch title="Break Down By Location" :value="locations" trend-only-text="Last 7 days"   :min-height="500"/>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import CardMagic from /* webpackChunkName: "card-magic" */ "@/components/ui/cards/CardMagic";

import {
  getLocations,
  getDirectShipment,
  getDropShipment,
  getTotalActivity,
  getShipmentDetails
} from "@/api/dashboard/operational.api";

export default {
  name: 'DashboardTabOperation',
  components: {
    CardMagic
  },
  data: () => ({
    loading: {
      totalActivity: true,
      directShipment: true,
      dropShipment: true,
      locations: true,
      shipmentDetails: true
    },
    locations: {},
    loadingCount: 0,
    totalActivity: {},
    directShipment: {},
    dropShipment: {},
    shipmentDetails: {}
  }),
  watch: {
    '$store.state.query.params': {
      immediate: true,
      handler:function() {
        console.log('this.$route.name', this.$route.name);
        if (this.$route.name !== 'dashboard-operation' && this.$route.name !== 'dashboard')
          return;
        console.log('this.$route.name', this.$route.name);
        this.update();
      }
    }
  },
  created() {
    console.log('DashboardTab CREATED');
  },
  methods: {
    setLoading(status) {
      Object.keys(this.loading).forEach((key)=>{
        this.loading[key] = status;
      });
    },
    update() {
      const params = this.$store.getters.params;
      this.setLoading(true);
      getTotalActivity(params).then((data)=>{
        this.totalActivity = data;
        this.loading.totalActivity = false;
      });
      getDirectShipment(params).then((data)=>{
        this.directShipment = data;
        this.loading.directShipment = false;
      });
      getDropShipment(params).then((data)=>{
        this.dropShipment = data;
        this.loading.dropShipment = false;
      });
      getLocations(params).then((data)=>{
        this.locations = data;
        this.loading.locations = false;
      });
      getShipmentDetails(params).then((data)=> {
        console.log('getShipmentDetails page', data);
        this.shipmentDetails = data;
        this.loading.shipmentDetails = false;
      });
    }
  }
}
</script>
