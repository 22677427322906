import { genColor } from '@/utilities'
const customerStatus = {
    BE: 0,
    TF: 1,
    CA: 2,
}

export const customers = {
    status: customerStatus,
    // getArrayStatuses(apiData) {
    //     const result = [];
    //     const $this = this;
    //     debugger
    //     Object.keys(apiData).forEach((key) =>{
    //         const KEY = key.toUpperCase();
    //         if (customerStatus[KEY]) {
    //             result.push({
    //                 value: apiData[key],
    //                 status: customerStatus[KEY],
    //                 text: $this.titles[customerStatus[KEY]],
    //             });
    //         }
    //     })
    //     return result;
    // },
    colorMark: {
       [customerStatus.BE]: '#2D9CDB',
       [customerStatus.TF]: '#32AFAB',
       [customerStatus.CA]: '#FF7A00'
    },
    getColor(name) {
        const NAME = name.toUpperCase();
        return this.colorMark[customerStatus[NAME]] || genColor(NAME)
    }
}