import {convertValues, formatCurrency, getTrend} from "@/utilities";
import {customers} from "@/utilities/customers.const";

const convertObject =  {
    'cost':  formatCurrency,
    'profit':  formatCurrency,
     'grossProfit':  (v)=> v.toFixed() + '%'
};


const convertTrend = (data, currentName = 'total', lastName = 'last_day_total') =>  {
    const trend = getTrend(data[currentName], data[lastName]);
    return {
        [currentName]: parseInt(data[currentName]),
        [currentName + 'Source']: data[currentName],
        trend: (trend < 0 ? "" : "+") + trend + "%",
        trendSource: trend,
        lastCost: data[lastName]
    }
}
const convertCost = function(data, currentName = 'cost') {
    if (data[currentName])
        data[currentName] = formatCurrency(data[currentName])
    return data;
}

const convertItemsTable = function(data, {currentName = 'items', convertValue = convertObject} = {}) {
    const items = [];
    if (data[currentName])
        Object.keys(data.items).forEach((key)=> {
            items.push({
                markerColor: customers.getColor(key),
                ...convertValues(convertValue, data.items[key], {...data.items[key]})
            })
        })
    return items;
}

const convertObjectToXY = (obj) => {
    return Object.keys(obj).map( (key)=> ({
        x: moment(key).format(),
        y: obj[key] ? parseFloat(obj[key]).toFixed(2) : obj[key]
    }))
}
const convertItemsGraph = function(data, { currentName = 'items' } = {}) {
    const result = {
        markers: {
            colors: '#fff',
            strokeColors: [],
        },
        series: [],
        colors: []
    };
    if (data[currentName])
        Object.keys(data.items).forEach((key)=> {
            const graphItem = data.items[key];
            result.markers.strokeColors.push(customers.getColor(key))
            result.colors.push(customers.getColor(key))
            result.series.push({
                name: graphItem.title,
                data: convertObjectToXY(graphItem.items)
            })
        })
    return result;
}

const convertToTable = function (data, {currentItem = 'items', convertValue = convertObject} = {}) {
    return {
        ...convertValues(convertValue, data, {...data}),
       // cost:  formatCurrency(data[currentName]),
        //costSource:  data[currentName],
        items: convertItemsTable(data,  {currentName: currentItem, convertValue})
    };
}

const addPercentInTable = function (data, {currentName = 'cost', currentItem = 'items'} = {}) {
    if (!Array.isArray(data[currentItem]) || data[currentName + 'Source'] === undefined)
        return data;

    const percent =  parseFloat(data[currentName + 'Source']) / 100;

    return {
        ...data,
        items: data[currentItem].map((item)=> ({...item, percent: (item[currentName + 'Source'] / percent).toFixed(2) + '%'}))
    };
}

export {
    convertTrend,
    convertCost,
    convertItemsTable,
    convertObjectToXY,
    convertItemsGraph,
    convertToTable,
    addPercentInTable
}
