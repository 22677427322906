/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function genColor(str) {
    function hashCode(str) { // java String#hashCode
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    var hash = Math.abs(hashCode(str)*1595);
    var colour = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

function formatCurrency(value, convert = true) {
    if (!convert)
        return value;

    if (value == undefined) {
       return 
    }
    const money = value.toLocaleString('en', {maximumFractionDigits: 0});
    // const moneyParts = money.split(',');
    // let digit = '';
    // switch (moneyParts.length - 1) {
    //     case 1: digit = 'K'; break;
    //     case 2: digit = 'M'; break;
    //     case 3: digit = 'B'; break;
    //     case 4: digit = 'T'; break;
    // }
    // return '$'+parseInt(moneyParts[0])+digit;
    return '$' + money;
}


/*
* @currency: Object =  {'cost': formatCurrency, 'profit': formatCurrency,  'grossProfit': (v)=>v+'text', }
* @source: Object = {cost: 110000, profit: 2000000, grossProfit: 3 }
* @result: Object = {cost: $110k, profit: $2M, grossProfit: '3text' }
* */
function convertValues(convertValue, source, result = {}) {
    if (!source)
        return result;

    Object.keys(convertValue).forEach((key)=> {
        const func = convertValue[key];
        if (typeof func !== 'function')
            return

        if (source[key]) {
            result[key] = func(source[key]);
            result[key + 'Source'] = source[key];
        }
    })
    return result;
}

function getTrend(total, lastTotal) {
    if (!lastTotal)
        return 0;
    const depend = lastTotal / 100;
    return (!total)
        ? -100
        : (total / depend - 100).toFixed();
}

export { getRandomInt, genColor, formatCurrency, convertValues, getTrend}