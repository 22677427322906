<template>
  <div class="d-flex flex-wrap justify-space-between text-center"
       :class="{'pt-6 pb-6': !separate && !stretch && padding}"
       :style="{backgroundColor: !stretch && !separate && bgColor, height: stretch ? '100%' : undefined}"
  >
    <div class="column-line__item" :class="{
      'column-line__item--break': !stretch && !separate,
      'column-line__item--separate': separate,
      'column-line__item--stretch': stretch
    }" v-for="(item, idx) in items" :key="idx">
      <v-img v-if="item.status === RISK" class="d-inline-flex mr-4"  style="position: absolute; top: 8px; left: 15px" contain width="14"  height="12" :src="require('@/assets/icons/ic-risk.svg')"></v-img>
      <div class="column-line__item-value font-weight-bold" :style="{color: !separate && getColorValue(item.status) }">
        {{item.value}}
      </div>
      <p class="mb-0 column-line__item-text font-weight-semi-bold" :style="{color: separate && getColorText(item.status) }">
        {{item.text}}
      </p>
      <div v-if="item.markerColor"
           class="column-line__item-marker"
           :style="{backgroundColor: item.markerColor }"></div>
    </div>
  </div>
</template>

<script>
import { shipments } from '@/utilities/statuses.const'

export default {
  name: "CardColumnLIne",
  props: {
    bgColor: {
      type: String,
      default: '#FBFBFB',
    },
    items: {
      type: Array,
      default: ()=>[{"value":0,"status":1,"text":"Ready to Ship"},{"value":0,"status":10,"text":"In process"},{"value":0,"status":8,"text":"At Risk"}],
    },
    padding: {
      type: Boolean,
      default: true,
    },
    separate: {
      type: Boolean,
      default: false
    },
    stretch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      style: 'column-lin',
      ...shipments.status
    }
  },
  methods: {
    getColorValue(status) {
      return shipments.colorValue[status];
    },
    getColorText(status) {
      return shipments.colorText[status];
    }
  }
}
</script>

<style lang="scss" scoped>
  .column-line {
    &__item {
      position: relative;
      flex: 1;
      padding-left: 10px;
      padding-right: 5px;
      >div {
        word-break: break-all;
      }
    }

    &__item:last-child::after {
      display: none;
    }

    &__item--break::after {
      content: '';
      position: absolute;
      right: -1px;
      top: 9px;
      width: 2px;
      height: 30px;
      border-radius: 2px;
      background-color: #E5E5E5;
    }

    &__item--separate {
      background: #FBFBFB !important;
      padding: 19px 10px 19px 10px;
      min-width: 100px;
      min-height: 80px;
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;

      flex-direction: column;
      align-items: center;
    }

    &__item--separate:last-child {
      margin-right: 0;
    }

    &__item--stretch {
      background: #FBFBFB !important;
      padding: 0 10px 10px 10px;
      min-width: 150px;
      margin-bottom: 10px;
      margin-right: 10px!important;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      >div {
        order: 3
      }
      >div.column-line__item-value {
        order: 1;
        margin-top: auto;
        margin-bottom: auto;
        padding: 20px 0 20px 0;
        font-family: 'Rubik', sans-serif;
        font-style: normal !important;
        font-weight: 300 !important;
        font-size: 2.5rem;
        line-height: 2.2rem;
        text-align: center;
        text-transform: uppercase;
      }
      >.column-line__item-text {
        order: 0;
        min-height: 70px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-family: 'Nunito', sans-serif  !important;
        font-style: normal;
        font-weight: 700 !important;
        font-size: 16px  !important;
        line-height: 22px  !important;
        text-align: center;
      }
    }


    &__item-value {
      margin-bottom: 4px;
      font-size: 24px;
      line-height: 29px;
    }

    &__item-text {
      font-size: 14px;
      line-height: 19px;
    }

    &__item-marker {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-top: 8px;
      border-radius: 50%;
    }
  }
</style>