import { getData } from "@/api/http-client";
//import { customers } from '@/utilities/customers.const';
import { formatCurrency } from '@/utilities';
import camelcaseKeys from 'camelcase-keys'
const END_POINT = '/dashboard/finance';
//convertCost, addPercentInTable, convertToTable, convertItemsGraph, convertTrend
import {convertToTable} from "@/api/dashboard/transform.api";

const convertObject =  {
    'cost':  formatCurrency,
    'profit':  formatCurrency,
    'grossProfit':  (v)=> v.toFixed() + '%'
};

const gerRevenue = async (params) => {
    const data = await getData( {path: END_POINT + '/revenue', params });
    return  {
        //...convertCost(convertTrend(data, 'cost',  'last_day_total')),
        headers: [
            {text: 'Customer', value: 'title', width: '60%'},
            {text: '', value: 'cost', class:['text-right']},
        ],
        ...convertToTable(data)
    };
}

const costOfGoods = async (params) => {
    const data = await getData( {path: END_POINT + '/cost-of-goods', params });
    return {
        //...convertCost(convertTrend(data, 'cost',  'last_day_cost')),
        headers: [
            {text: 'Customer', value: 'title', width: '60%'},
            {text: '', value: 'cost', class:['text-right']},
        ],
        ...convertToTable(data)
    };
}

const getProfitability = async (params) => {
    let data = await getData( {path: END_POINT + '/profitability', params });
    data = camelcaseKeys(data, {deep: true});
    return {
        //...convertCost(convertTrend(data, 'profit',  'last_day_profit')),
        headers: [
            {text: 'Customer', value: 'title', width: '40%'},
            {text: '', value: 'profit', class:['text-right']},
            {text: '', value: 'grossProfit', class:['text-right']},
        ],
        ...convertToTable(data, { convertValue: convertObject })
    };
}


const getInvoicesIssued = async (params) => {
    const data = await getData( {path: END_POINT + '/total-invoices', params });
    return {
        //...convertTrend(data, 'total',  'last_day_total'),
        headers: [
            {text: 'Customer', value: 'title', width: '40%'},
            {text: '', value: 'total', class:['text-right']},
        ],
        ...convertToTable(data, { convertValue: convertObject })
    };
}


const getVendorBills = async (params) => {
    const data = await getData( {path: END_POINT +  '/vendor-bills', params });
    return {
        //...convertTrend(data, 'total',  'last_day_total'),
        headers:  [
            {text: 'Vendors', value: 'title', width: '40%'},
            {text: 'AMT', value: 'cost', class:['text-right']},
            {text: 'No. Of', value: 'total', class:['text-right']}
        ],
        ...convertToTable(data, { convertValue: convertObject })
    };
}


const getStockValue = async (params) => {
    const data = await getData( {path: END_POINT +  '/stock-value', params });
    return {
        //...convertTrend(data, 'total',  'last_day_total'),
        headers:  [
            {text: 'Vendors', value: 'title', width: '40%'},
            {text: '', value: 'total', class:['text-right']},
            {text: '', value: 'cost', class:['text-right']},
        ],
        ...convertToTable(data, { convertValue: convertObject })
    };
}


export {
    gerRevenue,
    costOfGoods,
    getProfitability,
    getInvoicesIssued,
    getVendorBills,
    getStockValue
}